function generateStepValues (min, max) {
  // max 100000
  const result = [];
  const stepList = [
    { start: 0.1, end: 1, step: 0.1 },
    { start: 1, end: 10, step: 1 },
    { start: 10, end: 100, step: 5 },
    { start: 100, end: 1000, step: 50 },
    { start: 1000, end: 10000, step: 500 },
    { start: 10000, end: 100000, step: 5000 },
  ];

  stepList.forEach(({ start, end, step }) => {
    for (let i = start; i < end && i < max; i += step) {
      if (i > min) result.push(Number(i.toFixed(1)));
    }
  });

  return [...new Set(result)];
};

const steps = generateStepValues(0, 100000);
const minDistanceStep = 30;
const maxDistanceStep = 250;

export const calculateAngle = (origin, pointA, axis, zoom) => {
  // The actual distance between O and A in the simulation space.
  const delta_m_x = Number(axis.x);
  const delta_m_y = Number(axis.y);
  
  // The pixel distance between O and A in the pixel space.
  const delta_px_x = Number(pointA.x * zoom) - Number(origin.x * zoom);
  const delta_px_y = Number(pointA.y * zoom) - Number(origin.y * zoom);

  // Calculate the rotation angle theta between the pixel space and the simulation space.
  const theta = Math.atan2(delta_m_y, delta_m_x) - Math.atan2(-delta_px_y, delta_px_x);

  let angleDegrees = (theta * 180) / Math.PI;

  return angleDegrees;
};

export const generatePointMark = (currentPoint, origin, angle, zoom) => {
  const alpha = (angle * Math.PI) / 180;
  const cosAlpha = Math.cos(alpha);
  const sinAlpha = Math.sin(alpha);

  const deltaX = currentPoint.x * zoom - origin.x * zoom;
  const deltaY = currentPoint.y * zoom - origin.y * zoom;

  const xPrime = deltaX * cosAlpha - deltaY * sinAlpha + origin.x * zoom;
  const yPrime = deltaX * sinAlpha + deltaY * cosAlpha + origin.y * zoom;

  return { x: xPrime, y: yPrime };
};

const calculateIntersection = (origin, pointMark, calibration, zoom) => {
  const O = origin;
  const A = pointMark;
  const B = calibration;

  // Calculate the direction vector of the line OA.
  const dx = A.x - O.x * zoom;
  const dy = A.y - O.y * zoom;

  // The case where the line OA is vertical. (dx == 0)
  if (dx === 0) {
    // The perpendicular line will intersect at the point with the x-coordinate equal to O.x and the y-coordinate equal to B.y
    return { x: O.x * zoom, y: B.y * zoom };
  }

  // The case where the line OA is horizontal (dy == 0)
  if (dy === 0) {
    // The perpendicular line will intersect at the point with the y-coordinate equal to O.y and the x-coordinate equal to B.x
    return { x: B.x * zoom, y: O.y * zoom };
  }

  // Calculate the slope of the line OA.
  const m = dy / dx;

  // Calculate the slope of the perpendicular line (-1 / m)
  const mPerpendicular = -1 / m;

  // The equation of the line OA: y = m * (x - O.x) + O.y
  // The equation of the perpendicular line at B.: y = mPerpendicular * (x - B.x) + B.y

  // Solve the intersection equation:
  const x = (mPerpendicular * B.x * zoom - m * O.x * zoom + O.y * zoom - B.y * zoom) / (mPerpendicular - m);
  const y = m * (x - O.x * zoom) + O.y * zoom;

  return { x: x / zoom, y: y / zoom };
};

export const generateRatio = (origin, pointMark, calibration, axis, angle, zoom) => {
  if (axis.x !== null && axis.y !== null) {
    let pointMarkHandle = pointMark, valueHandle = Number(axis.x);

    if (Number(axis.x) < Number(axis.y)) {
      pointMarkHandle = generatePointMark(
        { x: origin.x, y: origin.y },
        { x: origin.x, y: origin.y + 1 },
        angle + 90,
        zoom
      );
      valueHandle = Number(axis.y);
    }
  
    const perpendicularPoint = calculateIntersection(origin, pointMarkHandle, calibration, zoom);

    
    const distance = Math.sqrt((perpendicularPoint.x * zoom - origin.x * zoom) ** 2 + (perpendicularPoint.y * zoom - origin.y * zoom) ** 2);
    
    return distance / valueHandle;
  }
}

// Handle step
const rotatePoint = (currentPoint, angle) => {
  const alpha = (angle * Math.PI) / 180;
  const cosAlpha = Math.cos(alpha);
  const sinAlpha = Math.sin(alpha);

  const deltaX = currentPoint.x;
  const deltaY = currentPoint.y;

  const xPrime = deltaX * cosAlpha - deltaY * sinAlpha;
  const yPrime = deltaX * sinAlpha + deltaY * cosAlpha;

  return({ x: xPrime, y: yPrime });
}

const generateStepToList = (stepSize, width, widthNegative, angle, ratio, arrowSize) => {
  const stepList = [];

  let widthStep = 0, indexStep = 0;
  while (widthStep < width - arrowSize) {
    if (widthStep > 0) {
      const stepPointRotate = rotatePoint({ x: widthStep, y: 0 }, angle)
      const stepPoint = {
        ...stepPointRotate,
        stepText: stepSize < 1 ? (stepSize * indexStep).toFixed(1) : stepSize * indexStep
      }
      stepList.push(stepPoint)
    }
    widthStep += stepSize * ratio;
    indexStep++;
  }

  let widthNegativeStep = 0, indexNegativeStep = 0;
  while (widthNegativeStep < widthNegative) {
    if (widthNegativeStep > 0) {
      const stepPointRotate = rotatePoint({ x: widthNegativeStep, y: 0 }, angle + 180)
      const stepPoint = {
        ...stepPointRotate,
        stepText: stepSize < 1 ? (stepSize * indexNegativeStep * -1).toFixed(1) : stepSize * indexNegativeStep * -1
      }
      stepList.unshift(stepPoint)
    }
    widthNegativeStep += stepSize * ratio;
    indexNegativeStep++;
  }

  return stepList;
}

export const generateStepList = (
  endOx, widthOx,
  endOy, widthOy,
  origin,
  angle,
  axis,
  zoom,
  arrowSize,
  ratioAxis
) => {
  let valueHandle = Number(axis.x),
      endPointHandle = endOx;

  if (Number(axis.x) < Number(axis.y)) {
    valueHandle = Number(axis.y);
    endPointHandle = endOy;
  }

  const width = Math.sqrt((endPointHandle.x - origin.x * zoom) ** 2 + (endPointHandle.y - origin.y * zoom) ** 2);

  const maxStep = Math.floor(width / minDistanceStep);

  let stepSize;
  for (let index = 0; index < steps.length; index++) {
    const step = steps[index];
    if (
      Math.floor(valueHandle * ratioAxis / step) <= maxStep && 
      step * ratioAxis > minDistanceStep &&
      step * ratioAxis < maxDistanceStep
    ) {
      stepSize = step;
      break;
    }
  }

  if (!stepSize) {
    if (valueHandle > 0 && valueHandle <= 1) stepSize = 0.1;
    if (valueHandle > 1 && valueHandle <= 10) stepSize = 1;
    if (valueHandle > 10 && valueHandle <= 100) stepSize = 10;
    if (valueHandle > 100 && valueHandle <= 1000) stepSize = 100;
    if (valueHandle > 1000 && valueHandle <= 10000) stepSize = 1000;
  }

  const widthOxHandle = Math.sqrt((endOx.x - origin.x * zoom) ** 2 + (endOx.y - origin.y * zoom) ** 2);
  const widthNegativeOxHandle = widthOx - widthOxHandle;
  const widthOyHandle = Math.sqrt((endOy.x - origin.x * zoom) ** 2 + (endOy.y - origin.y * zoom) ** 2);
  const widthNegativeOyHandle = widthOy - widthOyHandle;

  const stepOx = generateStepToList(stepSize, widthOxHandle, widthNegativeOxHandle, angle, ratioAxis, arrowSize);
  const stepOy = generateStepToList(stepSize, widthOyHandle, widthNegativeOyHandle, angle - 90, ratioAxis, arrowSize);

  return { stepOx, stepOy }
}