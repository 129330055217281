import { memo, useContext, useEffect, useMemo } from "react";
import { MapContext } from "../../plan/Tab/planMapKirihaMode";
import { QUARANT_POSITION } from "../../../utils/constants";
import { generatePointMark, generateRatio, generateStepList } from "../../../utils/kiriha";

const margin = 5;
const arrowSize = 8;

const AxisSystemView = () => {
  const {
    originPosition,
    calibration,
    axis,
    zoom,
    angle,
    scale,
    boxWidth, boxHeight,
    updateQuarantPositon,
    updateRatioAxis
  } = useContext(MapContext);

  const pointMark = useMemo(() => {
    return generatePointMark(
      { x: originPosition.left + 1, y: originPosition.top },
      { x: originPosition.left, y: originPosition.top },
      angle, 
      zoom
    )
  }, [originPosition, angle, zoom])

  const quarantPositon = useMemo(() => {
    if (originPosition.left * zoom < pointMark.x && originPosition.top * zoom < pointMark.y) return QUARANT_POSITION.bottomRight;
    if (originPosition.left * zoom < pointMark.x && originPosition.top * zoom > pointMark.y) return QUARANT_POSITION.topRight;
    if (originPosition.left * zoom > pointMark.x && originPosition.top * zoom < pointMark.y) return QUARANT_POSITION.bottomLeft;
    if (originPosition.left * zoom > pointMark.x && originPosition.top * zoom > pointMark.y) return QUARANT_POSITION.topLeft;
  }, [pointMark, originPosition, zoom])

  const ratioAxis = useMemo(() => {
    return generateRatio(
      { x: originPosition.left, y: originPosition.top },
      pointMark,
      { x: calibration.left, y: calibration.top },
      axis,
      angle,
      zoom
    )
  }, [originPosition, pointMark, calibration, axis, angle, zoom])

  const stepList = useMemo(() => {
    const [startOx, endOx] = calculateExtendedLine(
      { x: originPosition.left * zoom, y: originPosition.top * zoom },
      { x: pointMark.x, y: pointMark.y }
    )
    const [startOy, endOy] = calculateExtendedLine(
      { x: originPosition.left * zoom, y: originPosition.top * zoom },
      { x: pointMark.x, y: pointMark.y },
      true
    )

    const arrowOxPositon = getArrowOxPositon(startOx, endOx);
    const arrowOyPositon = getArrowOyPositon(startOy, endOy);

    const widthOx = Math.sqrt((endOx.x - startOx.x) ** 2 + (endOx.y - startOx.y) ** 2);
    const widthOy = Math.sqrt((endOy.x - startOy.x) ** 2 + (endOy.y - startOy.y) ** 2);

    return generateStepList(
      arrowOxPositon, widthOx,
      arrowOyPositon, widthOy,
      { x: originPosition.left, y: originPosition.top },
      angle, axis, zoom, arrowSize, ratioAxis
    )
  }, [pointMark, originPosition, zoom, angle, axis, ratioAxis])

  useEffect(() => {
    updateRatioAxis(ratioAxis);
  }, [ratioAxis])

  useEffect(() => {
    updateQuarantPositon(quarantPositon);
  }, [quarantPositon])

  function calculateExtendedLine (O, B, isPerpendicular = false) {
    let slope;
    if (isPerpendicular) {
      if (B.x === O.x) {
        // If the original line is vertical, the perpendicular line is horizontal.
        return [
          { x: margin, y: O.y },
          { x: boxWidth - margin, y: O.y },
        ];
      } else if (B.y === O.y) {
        // If the original line is horizontal, the perpendicular line is vertical.
        return [
          { x: O.x, y: margin },
          { x: O.x, y: boxHeight - margin },
        ];
      } else {
        // Calculate the slope of the perpendicular line in typical cases.
        slope = -((B.x - O.x) / (B.y - O.y));
      }
    } else {
      if (B.x === O.x) {
        return [
          { x: O.x, y: margin },
          { x: O.x, y: boxHeight - margin },
        ];
      }
      // The slope of the original line.
      slope = (B.y - O.y) / (B.x - O.x); 
    }

    const intercept = O.y - slope * O.x;

    const left = { x: margin, y: slope * margin + intercept };
    const right = {
      x: boxWidth - margin,
      y: slope * (boxWidth - margin) + intercept,
    };
    const top = { x: (margin - intercept) / slope, y: margin };
    const bottom = {
      x: (boxHeight - margin - intercept) / slope,
      y: boxHeight - margin,
    };

    const points = [left, right, top, bottom].filter(p => 
      p.x >= margin && 
      p.x <= boxWidth - margin && 
      p.y >= margin && 
      p.y <= boxHeight - margin
    );

    return points.length >= 2 ? points.slice(0, 2) : [O, B];
  };

  function getArrowOxPositon (start, end) {
    let arrowOxPositon;

    if (quarantPositon === QUARANT_POSITION.topRight || quarantPositon === QUARANT_POSITION.bottomRight) {
      arrowOxPositon = start.x > end.x ? start : end;
    } else {
      arrowOxPositon = start.x < end.x ? start : end;
    }

    return arrowOxPositon;
  }

  function getArrowOyPositon (start, end) {
    let arrowOxPositon;

    if (quarantPositon === QUARANT_POSITION.topLeft || quarantPositon === QUARANT_POSITION.topRight) {
      arrowOxPositon = start.x < end.x ? start : end;
    } else {
      arrowOxPositon = start.x > end.x ? start : end;
    }

    return arrowOxPositon;
  }

  const renderOx = () => {
    const [start, end] = calculateExtendedLine(
      { x: originPosition.left * zoom, y: originPosition.top * zoom },
      { x: pointMark.x, y: pointMark.y }
    );

    const arrowOxPositon = getArrowOxPositon(start, end);

    const arrowHeadPoints = `
      ${arrowOxPositon.x},${arrowOxPositon.y} 
      ${arrowOxPositon.x - arrowSize / scale},${arrowOxPositon.y - arrowSize / 2 / scale}
      ${arrowOxPositon.x - arrowSize / scale},${arrowOxPositon.y + arrowSize / 2 / scale}
    `;

    return (
      <div 
        className="axis axis-ox"
      >
        <svg
          width={boxWidth}
          height={boxHeight}
        >
          <line
            x1={start.x}
            y1={start.y}
            x2={end.x}
            y2={end.y}
            stroke="#1BB34B"
            strokeWidth={1 / scale}
          />

          <polygon
            points={arrowHeadPoints}
            fill="#1BB34B"
            transform={`rotate(${angle}, ${arrowOxPositon.x}, ${arrowOxPositon.y})`}
          />

          <text
            x={arrowOxPositon.x - 8 / scale}
            y={arrowOxPositon.y + 10 / scale}
            fill="#1BB34B"
            fontSize={8 / scale}
            textAnchor="middle"
            transform={`rotate(${angle}, ${arrowOxPositon.x}, ${arrowOxPositon.y})`}
          >
            x
          </text>

          {stepList.stepOx.map((point, index) => (
            <g 
              key={index} 
              transform={`rotate(${angle}, ${originPosition.left * zoom + point.x}, ${originPosition.top * zoom + point.y})`}
            >
              <circle cx={originPosition.left * zoom + point.x} cy={originPosition.top * zoom + point.y} r={1.5 / scale} fill="#1BB34B" />
              <text
                x={originPosition.left * zoom + point.x}
                y={originPosition.top * zoom +  point.y + 10 / scale} // Position the index above the point
                fill="#1BB34B"
                fontSize={8 / scale}
                textAnchor="middle"
              >
                {point.stepText}
              </text>
            </g>
          ))}
        </svg>
      </div>
    )
  }

  const renderOy = () => {
    const [start, end] = calculateExtendedLine(
      { x: originPosition.left * zoom, y: originPosition.top * zoom },
      { x: pointMark.x, y: pointMark.y },
      true
    );

    let arrowOyPositon = getArrowOyPositon(start, end);


    const arrowHeadPoints = `
      ${arrowOyPositon.x},${arrowOyPositon.y} 
      ${arrowOyPositon.x - arrowSize / scale},${arrowOyPositon.y - arrowSize / 2 / scale} 
      ${arrowOyPositon.x - arrowSize / scale},${arrowOyPositon.y + arrowSize / 2 / scale}
    `;

    return (
      <div 
        className="axis axis-oy"
      >
        <svg
          width={boxWidth}
          height={boxHeight}
        >
          <line
            x1={start.x}
            y1={start.y}
            x2={end.x}
            y2={end.y}
            stroke="#1BB34B"
            strokeWidth={1 / scale}
          />

          <polygon
            points={arrowHeadPoints}
            fill="#1BB34B"
            transform={`rotate(${angle - 90}, ${arrowOyPositon.x}, ${arrowOyPositon.y})`}
          />

          <text
            x={arrowOyPositon.x - 8 / scale}
            y={arrowOyPositon.y + 10 / scale}
            fill="#1BB34B"
            fontSize={8 / scale}
            textAnchor="middle"
            transform={`rotate(${angle - 90}, ${arrowOyPositon.x}, ${arrowOyPositon.y})`}
          >
            y
          </text>

          {stepList.stepOy.map((point, index) => (
            <g 
              key={index} 
              transform={`rotate(${angle - 90}, ${originPosition.left * zoom + point.x}, ${originPosition.top * zoom + point.y})`}
            >
              <circle cx={originPosition.left * zoom + point.x} cy={originPosition.top * zoom + point.y} r={1.5 / scale} fill="#1BB34B" />
              <text
                x={originPosition.left * zoom + point.x}
                y={originPosition.top * zoom +  point.y + 10 / scale} // Position the index above the point
                fill="#1BB34B"
                fontSize={8 / scale}
                textAnchor="middle"
              >
                {point.stepText}
              </text>
            </g>
          ))}
        </svg>
      </div>
    )
  }

  if (axis.x !== null && axis.y !== null && axis.x !== "" && axis.y !== "") {
    return (
      <div className="axis-system">
        <span 
          className="axis-origin-point" 
          style={{ 
            transform: `translate(calc(${originPosition.left * zoom}px - 50%), calc(${originPosition.top * zoom}px - 50%)) scale(${1 / scale})`
          }}
        />
  
        {renderOx()}
        {renderOy()}
      </div>
    )
  }
}

export default memo(AxisSystemView);